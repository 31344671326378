import { type Organisation } from './types/bigmelo/organisations'
import { type Project } from './types/bigmelo/projects'

export const paths = {
	home: '/',
	createOrganisation: '/create-organisation',
	dashboard: {
		overview: '/dashboard',
		organisation: (organisationId: Organisation['id']) =>
			`/dashboard/${organisationId}`,
		projects: (organisationId: Organisation['id']) =>
			`/dashboard/${organisationId}/projects`,
		createProject: (organisationId: Organisation['id']) =>
			`/dashboard/${organisationId}/project/create`,
		project: (organisationId: Organisation['id'], projectId: Project['id']) =>
			`/dashboard/${organisationId}/project/${projectId}`,
		projectEdit: (
			organisationId: Organisation['id'],
			projectId: Project['id'],
		) => `/dashboard/${organisationId}/project/${projectId}/edit`,
		settings: {
			profile: '/dashboard/settings/profile',
			profilePhoto: '/dashboard/settings/profile/photo',
		},
	},
	components: {
		index: '/components',
		buttons: '/components/buttons',
		charts: '/components/charts',
		colors: '/components/colors',
		detailLists: '/components/detail-lists',
		forms: '/components/forms',
		gridLists: '/components/grid-lists',
		groupedLists: '/components/grouped-lists',
		inputs: '/components/inputs',
		modals: '/components/modals',
		quickStats: '/components/quick-stats',
		tables: '/components/tables',
		typography: '/components/typography',
	},
	notAuthorized: '/errors/not-authorized',
	notFound: '/errors/not-found',
	internalServerError: '/errors/internal-server-error',
	docs: 'https://material-kit-pro-react-docs.devias.io',
	purchase: 'https://mui.com/store/items/devias-kit-pro',
} as const
